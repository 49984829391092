import Vue from 'vue'
import Router from 'vue-router'
import Japanese from "./components/Japanese.vue";
import English from "./components/English.vue";
import notFound from "./Errors/NotFound.vue";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            component:Japanese
        },
        {
            path: '/english',
            component:English
        },
        {
            name: 'notFound',
            path: '*',
            component: notFound,
            meta: { title: 'お探しのページは見つかりませんでした' }}
    ]
});