<template>
  <div class="japanese">
    <div class="mainsection fade_in">
      <div class="headerLinks"></div>
      <div class="tabs clearfix">
        <div class="tabsLeft">
          <ul>
            <li><a>ページ</a></li>
          </ul>
        </div>
        <div class="tabsRight">
          <ul>
            <li><a class="active">閲覧</a></li>
            <li><a>ソースを編集</a></li>
            <li><a>履歴表示</a></li>
          </ul>
        </div>
      </div>
      <div class="article">
        <h1>Adbc株式会社</h1>
        <p class="siteSub">出典：Adbc株式会社</p>
        <p class="roleNote">
          この項目では、Adbc株式会社について説明しています。
        </p>
        <table class="rightbox">
          <caption class="rightbox-title">
            Adbc株式会社
          </caption>
          <tbody>
            <tr>
              <td colspan="2" class="rightbox-image">
                <img
                  alt="Adbc株式会社"
                  src="../assets/img/adbc-logo.png"
                  decoding="async"
                  width="220"
                  height="82"
                  data-file-width="512"
                  data-file-height="191"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  種類
                </div>
              </th>
              <td>株式会社</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  市場情報
                </div>
              </th>
              <td>非上場</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  本社所在地
                </div>
              </th>
              <td>
                <img
                  alt="日本の旗"
                  src="../assets/img/Japan.svg.png"
                  decoding="async"
                  width="25"
                  height="17"
                  class="thumbborder"
                  data-file-width="900"
                  data-file-height="600"
                />日本<br />
                <span style="color: red"><b>〒</b></span
                >176-0001<br />
                東京都練馬区練馬1丁目20番8号 日建練馬ビル2F
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  設立
                </div>
              </th>
              <td>2022年3月18日</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  業種
                </div>
              </th>
              <td>ソフトウェア業界、情報処理サービス業界、Web・インターネット業界</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  法人番号
                </div>
              </th>
              <td>0116−01−025817</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  事業内容
                </div>
              </th>
              <td>ネットワークシステムの設計・構築/ネットワークシステムの監視・保守/アプリケーションの設計/開発</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  代表者
                </div>
              </th>
              <td>代表取締役社長 松村 裕樹</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Adbc株式会社</strong
          >（エーディービーシー）は、東京都練馬区に本社を置き、システム開発・インフラ構築・WEB、モバイルサービスを提供する会社である。
        </p>
        <div
          class="contentsPanel"
          v-bind:class="{ minimizedPanel: !panelStatus }"
        >
          <div class="hidePanel" v-show="panelStatus" @click="hidePanel()">
            [非表示]
          </div>
          <div class="showPanel" v-show="!panelStatus" @click="showPanel()">
            [表示]
          </div>
          <div class="contentsHeader">目次</div>
          <ul v-bind:class="{ hidePanel: !panelStatus }">
            <li><span>1</span><a>概要</a></li>
            <li><span>2</span><a>企業理念</a></li>
            <li><span>3</span><a>サービス</a></li>
            <li><span>4</span><a>採用</a></li>
            <li><span>5</span><a>沿革</a></li>
            <li><span>6</span><a>問い合わせ</a></li>
          </ul>
        </div>
        <div class="content_main" id="overview">
          <h2>概要</h2>
          <p>
            Adbc株式会社は、IT分野におけるシステム開発・インフラ構築・WEB、モバイルサービスを提供する。本社は東京都練馬区。2022年3月18日に設立。
          </p>
        </div>
        <div class="content_main" id="philosophy">
          <h2>企業理念</h2>
          <p>
            「豊かな生活を送れること」を目標に「仕事のスキルも重要であるが、それ以上に仕事を俯瞰的に見て最適な行動をできる人材」を育成することに注力している。
            ITスキルの高さ以上に複雑なコミュニケーションを必要とする開発やシステム運用で発生する「コミュニケーションコスト」を低減させ、円滑に業務遂行することが第一だと考えている。
            そのスキルはIT分野に関わらず、どの業種や職種でも必要不可欠となるもので、人生において必要なスキルと言っても過言ではない。
            円滑な業務を行えるようになり、無駄な業務をなくしてプライベート時間を増やす、というようによいサイクルを生み出して欲しいと考えている。
          </p>
        </div>
        <div class="content_main" id="service">
          <h2>サービス</h2>
          <p>以下のサービスを展開している。</p>
          <ul>
            <li>システム開発/支援</li>
            <li>システムコンサルティング</li>
            <li>インフラ構築/設計</li>
            <li>セキュリティ診断</li>
          </ul>
        </div>
        <div class="content_main" id="recruitment">
          <h2>採用</h2>
          <ul>
            <li>募集職種：エンジニア/一般事務/営業</li>
            <li>雇用区分：正社員/契約社員</li>
            <li>勤務地：テレワーク（プロジェクト指定の場所）</li>
            <li>勤務時間：フレックス（8h/Day）</li>
            <li>保険：社会保険</li>
            <li>福利厚生：書籍購入/学費補助</li>
            <li>選考の流れ：①書類選考 ②面接</li>
            <li>給与：30万円〜（エンジニアの場合能力に応じて）</li>
          </ul>
        </div>
        <div class="content_main" id="history">
          <h2>沿革</h2>
          <ul>
            <li>2022年3月18日 設立</li>
          </ul>
        </div>
        <div class="content_main" id="contact">
          <h2>問い合わせ</h2>
          <p>採用やサービスに関する問合せは以下のreCAPTCHAを入力し表示されるメールアドレスにメール送信してください。</p>
          <div id="validate" class="fade_in" v-show="captchaStatus">
            <div class="container">
              <div id="captcha" class="captcha">
                {{ theCaptcha }}
              </div>
              <div class="restart">
                <button class="link-style-btn" @click="createCaptcha()">
                  表示を変更
                </button>
              </div>
              <div class="input">
                <input
                  type="text"
                  v-model="reCaptcha"
                  maxlength="6"
                  placeholder="表示文字を入力して下さい。"
                />
              </div>
              <div style="padding-top: 5px">
                <input
                  class="confirm_btn"
                  type="button"
                  value="お問い合わせ先を確認する"
                  @click="validateCaptcha()"
                />
              </div>
              <div class="errmsg fade_in" style="color: red">{{errMsg}}</div>
            </div>
          </div>
          <input
            class="btn_mail"
            id="btn_mail"
            type="button"
            value="詳細はこちら"
            @click="showCaptcha()"
            v-show="detailBtnStatus"
          />
        </div>
        <div id="contact_info fade_in" v-show="contactStatus">{{contactInfo}}</div>
      </div>
      <div class="pagefooter">
        最終更新 2022年4月1日 (金) 17:01 （日時は個人設定で未設定ならばUTC）。
        <div class="footerlinks">
          Copyright ©2022-2023 Adbc株式会社 All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "el-jpn",
  data: function () {
    return {
      detailBtnStatus: true,
      panelStatus: true,
      captchaStatus: false,
      contactStatus: false,
      theCaptcha: this.createCaptcha(),
      reCaptcha: "",
      errMsg: null,
      contactInfo: 'sales@adbcc.jp'
    };
  },
  methods: {
    hidePanel() {
      this.panelStatus = false
    },
    showPanel() {
      this.panelStatus = true
    },
    showCaptcha() {
      this.captchaStatus = true
      this.detailBtnStatus = false
    },
    createCaptcha() {
      let captcha = new Array();
      for (let q = 0; q < 6; q++) {
        if (q % 2 == 0) {
          captcha[q] = String.fromCharCode(Math.floor(Math.random() * 26 + 65));
        } else {
          captcha[q] = Math.floor(Math.random() * 10 + 0);
        }
      }
      return (this.theCaptcha = captcha.join(""));
    },
    validateCaptcha() {
      if (this.reCaptcha == "") {
        this.errMsg = "※表示文字の入力は必須です。";
      } else if (this.reCaptcha != this.theCaptcha) {
        this.errMsg = "※入力した文字に誤りがあります。";
      } else {
        this.errMsg = "";
        this.panelStatus = false
        this.captchaStatus = false
        this.contactStatus = true
        console.log(this.contactStatus)
      }
    },
  },
};
</script>
